<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <router-link to="/home" class="back-button">
            <ion-icon slot="icon-only" color="tetranary" :icon="chevronBackOutline"></ion-icon>
          </router-link>
        </ion-buttons>

        <ion-title color="primary">
          <h2 class="mb-0 ion-text-center">My Child</h2>
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :scroll-events="true">
      <div id="container">
        <div class="ion-padding horizontally-centered">
          <p>
            In this section we ask you to create a profile and complete information about your
            child, such as their age. In addition, you can complete a two week sleep diary ready to
            take to your first appointment for support with your sleep. Further information will be
            sent to your email when you register.
          </p>
          <p>
            To use the My Child feature, you first need to register for an account. Please complete
            the form below. We will not share your details with any 3rd parties.
          </p>
          <p class="ion-margin-top">
            Have an account? <router-link to="/login">Login here</router-link>.
          </p>
        </div>

        <form action="" @submit.prevent="register" class="horizontally-centered">
          <ion-grid>
            <ion-row class="ion-justify-content-center ion-align-items-center">
              <ion-col align-self-center>
                <ion-item class="ion-margin-top" lines="none">
                  <ion-label position="stacked">Email</ion-label>
                  <ion-input type="email" required v-model="email"></ion-input>
                </ion-item>

                <ion-item class="ion-margin-top" lines="none">
                  <ion-label position="stacked">Password</ion-label>
                  <ion-input type="password" required v-model="password"></ion-input>
                </ion-item>

                <div class="ion-margin-top ion-padding-top">
                  <ion-button
                    expand="block"
                    size="large"
                    type="submit"
                    color="tertiary"
                    :disabled="formSubmitted"
                  >
                    <ion-spinner v-if="formSubmitted" name="crescent"></ion-spinner>
                    Register
                    <ion-ripple-effect></ion-ripple-effect>
                  </ion-button>
                </div>

                <transition name="fade" mode="out-in">
                  <div class="ion-padding" v-if="error">
                    <ion-text color="danger" v-html="error"> </ion-text>
                  </div>
                </transition>
              </ion-col>
            </ion-row>
          </ion-grid>
        </form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { chevronBackOutline } from 'ionicons/icons';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { reactive, toRefs } from 'vue';

import axios from '@/axios';
import {
  IonPage,
  IonIcon,
  IonText,
  IonHeader,
  IonToolbar,
  IonSpinner,
  IonRippleEffect,
  IonTitle,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton
} from '@ionic/vue';

export default {
  name: 'Register',
  components: {
    IonPage,
    IonIcon,
    IonText,
    IonHeader,
    IonToolbar,
    IonSpinner,
    IonRippleEffect,
    IonTitle,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonButton
  },
  setup(): any {
    const store = useStore();
    const route = useRouter();

    const state = reactive({
      formSubmitted: false,
      error: ''
    });

    const user = reactive({
      email: '',
      password: ''
    });

    const APIregister = function() {
      return axios.post('wp-json/user/v1/register', {
        email: user.email,
        password: user.password
      });
    };

    const registerSuccessful = function(res) {
      if (res.status !== 200) {
        registerFailed(res);
      }

      login();
    };

    const APIlogin = function() {
      return axios.post('wp-json/jwt-auth/v1/token', {
        username: user.email,
        password: user.password
      });
    };

    const loginFailed = function(err) {
      state.formSubmitted = false;
      const message = 'There was a problem with the login process. Please try again later.';
      state.error = err.response?.data?.message || message;
      // this.$store.dispatch('logout');
    };

    const loginSuccessful = function(res) {
      if (res.status !== 200 || !res.data?.token) {
        loginFailed(res);
      }

      // store details in localStorage
      store.dispatch('auth/login', res.data.token);
      // get user data
      store.dispatch('user/getDataAPI');
      route.replace('/profile');
    };

    const login = async function() {
      await APIlogin()
        .then(loginSuccessful)
        .catch(loginFailed);
    };

    const registerFailed = function(err) {
      state.formSubmitted = false;

      let message =
        err.response?.data?.message ||
        'There was a problem with the login process. Please try again later.';

      if (
        message === `Email already exists, please try 'Reset Password'` ||
        message === 'Username already exists, please enter another username'
      ) {
        message = `Email is already registered.`;
      }

      state.error = message;
    };
    const register = async function() {
      await APIregister()
        .then(registerSuccessful)
        .catch(registerFailed);
    };

    return {
      ...toRefs(user),
      ...toRefs(state),
      register,
      chevronBackOutline
    };
  }
};
</script>
