
import { chevronBackOutline } from 'ionicons/icons';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { reactive, toRefs } from 'vue';

import axios from '@/axios';
import {
  IonPage,
  IonIcon,
  IonText,
  IonHeader,
  IonToolbar,
  IonSpinner,
  IonRippleEffect,
  IonTitle,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton
} from '@ionic/vue';

export default {
  name: 'Register',
  components: {
    IonPage,
    IonIcon,
    IonText,
    IonHeader,
    IonToolbar,
    IonSpinner,
    IonRippleEffect,
    IonTitle,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonButton
  },
  setup(): any {
    const store = useStore();
    const route = useRouter();

    const state = reactive({
      formSubmitted: false,
      error: ''
    });

    const user = reactive({
      email: '',
      password: ''
    });

    const APIregister = function() {
      return axios.post('wp-json/user/v1/register', {
        email: user.email,
        password: user.password
      });
    };

    const registerSuccessful = function(res) {
      if (res.status !== 200) {
        registerFailed(res);
      }

      login();
    };

    const APIlogin = function() {
      return axios.post('wp-json/jwt-auth/v1/token', {
        username: user.email,
        password: user.password
      });
    };

    const loginFailed = function(err) {
      state.formSubmitted = false;
      const message = 'There was a problem with the login process. Please try again later.';
      state.error = err.response?.data?.message || message;
      // this.$store.dispatch('logout');
    };

    const loginSuccessful = function(res) {
      if (res.status !== 200 || !res.data?.token) {
        loginFailed(res);
      }

      // store details in localStorage
      store.dispatch('auth/login', res.data.token);
      // get user data
      store.dispatch('user/getDataAPI');
      route.replace('/profile');
    };

    const login = async function() {
      await APIlogin()
        .then(loginSuccessful)
        .catch(loginFailed);
    };

    const registerFailed = function(err) {
      state.formSubmitted = false;

      let message =
        err.response?.data?.message ||
        'There was a problem with the login process. Please try again later.';

      if (
        message === `Email already exists, please try 'Reset Password'` ||
        message === 'Username already exists, please enter another username'
      ) {
        message = `Email is already registered.`;
      }

      state.error = message;
    };
    const register = async function() {
      await APIregister()
        .then(registerSuccessful)
        .catch(registerFailed);
    };

    return {
      ...toRefs(user),
      ...toRefs(state),
      register,
      chevronBackOutline
    };
  }
};
